<template>
  <v-container fluid>
    <div class="main">
      <v-row>
        <v-col cols="12" md="3">
          <v-card class="rounded-lg" elevation="0">
            <v-card-text>
              <v-list nav>
                <v-list-item-group v-model="model" mandatory>
                  <v-list-item
                      color="primary"
                      class="translateY nav-list"
                      active-class="border--primary"
                      v-for="(item, i) in items"
                      :key="i"
                      :value="item.value"
                  >
                    <v-list-item-icon>
                      <v-icon color="primary" dense>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="9">
          <Devices v-show="model === 1" ref="devicesList"/>
          <SmsService v-show="model === 2" ref="smsService"/>
          <FlexyService v-show="model === 3" ref="flexyService"/>
          <CardService v-show="model === 4" ref="cardService"/>
          <EasyFlexy v-show="model === 5" ref="easyFlexy"/>
          <Epayment v-show="model === 6" ref="epayment"/>
          <AppMenu v-show="model === 7" ref="appMenu"/>
          <HomeSteps v-show="model === 8" ref="homeSteps"/>
          <Cashback v-show="model === 9" ref="cashback"/>
          <Env v-show="model === 10" ref="env"/>
          <Versions v-show="model === 11" ref="versions"/>
          <VerifcationMethod v-show="model === 12" ref="verifcationMethod"/>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>


<script>
import Devices from "./smsdevice/index.vue";
import SmsService from "./smsService/index.vue";
import FlexyService from "./flexyService/index.vue";
import CardService from "./cardService/index.vue";
import EasyFlexy from "./easyFlexy/index.vue";
import Cashback from "./cashback/index.vue";

import Epayment from './epayment/index.vue';
import AppMenu from './appMenu/index.vue';
import HomeSteps from './homeSteps/index.vue';
import Env from './env/index.vue';
import Versions from './versions/index.vue';
import VerifcationMethod from './verifcationMethod/index.vue';

export default {
  components: {
    Devices,
    Env,
    SmsService,
    FlexyService,
    EasyFlexy,
    CardService,
    Epayment,
    AppMenu,
    HomeSteps,
    Cashback,
    VerifcationMethod,
    Versions
  },
  data() {
    return {
      items: [
        {text: "Appareils", icon: "mdi-clipboard-text-outline", value: 1},
        {text: "Service SMS", icon: "mdi-clipboard-text-outline", value: 2},
        {text: "API Flexy", icon: "mdi-clipboard-text-outline", value: 3},
        {text: "API Cartes", icon: "mdi-clipboard-text-outline", value: 4},
        {text: "Paramètres Easy Flexy", icon: "mdi-clipboard-text-outline", value: 5},
        {text: "Paramètres E-payment", icon: "mdi-credit-card-settings-outline", value: 6},
        {text: "Application Mobile", icon: "mdi-cellphone-text", value: 7},
        {text: "Étapes page d'accueil", icon: "mdi-file-video-outline", value: 8},
        {text: "Cashback", icon: "mdi-cash-lock", value: 9},
        {text: "Environnement", icon: "mdi-code-braces-box", value: 10},
        {text: "Versions", icon: "mdi-update", value: 11},
        {text: "M. vérification des utilisateurs", icon: "mdi-account-check-outline", value: 12},
      ],
      model: 1,
    };
  },
  methods: {},
  computed: {},
  mounted() {
    this.$refs.devicesList.getItems();
  },
  watch: {
    model(val) {
      switch (val) {
        case 1:
          return this.$refs.devicesList.getItems();
        case 2:
          return this.$refs.smsService.fetchData();
        case 3:
          return this.$refs.flexyService.fetchData();
        case 4:
          return this.$refs.cardService.fetchData();
        case 5:
          return this.$refs.easyFlexy.fetchData();
        case 6:
          return this.$refs.epayment.fetchData();
        case 7:
          return this.$refs.appMenu.fetchData();
        case 8:
          return this.$refs.homeSteps.fetchData();
        case 9:
          return this.$refs.cashback.fetchData();
        case 10:
          return this.$refs.env.fetchData();
        case 11:
          return this.$refs.versions.fetchData();
        case 12:
          return this.$refs.verifcationMethod.fetchData();
      }
    },
  },
};
</script>
